<template>
  <div
    class="p-12 bg-primary bg-lighten-5 d-flex flex-column"
    style="min-height: 100vh;"
  >

    <b-link
      href="https://account.surfe.com"
      class="brand-logo"
    >
      <b-img
        :src="require('@/assets/images/logo/logo.svg')"
        alt="Surfe logo"
        style="max-width:150px"
      />
    </b-link>

    <div class="misc-inner d-flex flex-column justify-content-center flex-grow-1">
      <!-- Confirmed -->
      <div
        id="confirmed"
        class="w-100 text-center"
        style="display:none;"
      >
        <div class="d-flex flex-column align-items-center">
          <div
            class="d-flex align-items-center justify-content-center bg-primary bg-lighten-5"
          >
            <div
              class="d-flex position-relative"
              style="width: 433px; height: 340px;"
            >
              <b-img
                fluid
                :src="require('@/assets/images/wave-pink.svg')"
                alt="Login illustration"
                class="position-absolute position-left-0 position-right-0"
                style="top: 39.51%; bottom: 31.06%; width: 433px; height: 100px;"
              />
              <b-img
                fluid
                :src="require('@/assets/images/register-illustration.png')"
                :srcset="require('@/assets/images/register-illustration.png') + ' 1x, ' + require('@/assets/images/register-illustration@2x.png') + ' 2x'"
                alt="Login illustration"
                class="position-absolute position-right-0 position-bottom-0"
                style="left: -2.77%; top: 2.94%; width: 445px; height: 330px;"
              />
            </div>
          </div>

          <div style="width: 377px;">
            <h2 class="mt-5">
              You're all set!
            </h2>

            <p class="mt-2_5 mb-10 text-muted">
              Your email address is confirmed.<br>
              You can start using Surfe.
            </p>

            <b-button
              variant="primary btn-block"
              href="https://www.linkedin.com/in/david-maurice-chevalier/"
            >
              Go to LinkedIn
            </b-button>
          </div>
        </div>
      </div>

      <!-- Error -->
      <div
        id="error"
        class="w-100 text-center"
        style="display:none;"
      >
        <h1 class="mb-2 mt-10">
          Oops...
        </h1>

        <p
          id="error-text"
          class="mb-4"
        >
          An error occurred.
        </p>
      </div>
    </div>
  </div>
</template>

<script>
/* eslint-disable global-require */
import { BImg, BButton, BLink } from 'bootstrap-vue'

export default {
  components: {
    BImg, BButton, BLink,
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-auth.scss';
@import '@core/scss/vue/pages/page-misc.scss';
</style>
